import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Skill Day`}</strong></p>
    <p>{`10:00 EMOM`}</p>
    <p>{`Minute 1, Right Arm:`}</p>
    <p>{`5-Single Arm Russian KBS’s (53/35)`}</p>
    <p>{`5-Single Arm Hang Power Cleans`}</p>
    <p>{`5-Single Arm Hang Power Snatches`}</p>
    <p>{`Minute 2, repeat with Left Arm`}</p>
    <p>{`rest, then:`}</p>
    <p>{`20:00 EMOM`}</p>
    <p>{`Minute 1:00: 20 KBS’s (53/35)`}</p>
    <p>{`Minute 2:00: 20 V Ups`}</p>
    <p>{`Minute 3:00: 15 Burpees`}</p>
    <p>{`Minute 4:00: :20 Hollow Rock`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      